body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

div#root {
  height: calc(var(--vh, 1vh) * 100) !important;
  overflow: hidden;
}

img {
  transition: filter 300ms ease-in-out;
}

img.lazy {
  filter: blur(20px);
}

/* videojs css override */
.video-js .vjs-big-play-button {
  transition: none !important;
  background: transparent !important;
  border: none !important;
  width: 100% !important;
  height: 100% !important;
}

.vjs-big-play-centered .vjs-big-play-button {
  top: 0 !important;
  left: 0 !important;
  margin-top: 0px !important;
  margin-left: 0px !important;
}

.vjs-poster {
  background-size: cover;
}


/* override 8thwall elements */

#actionButton {
  /* change color of action button */
  /* background-color: #007aff !important; */
}

#previewContainer.image-preview {
  z-index: 3010;
  background-color: #fff;
}

#previewContainer.image-preview .top-bar .preview-box {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transform: none;
}

#previewContainer.image-preview .top-bar .preview-box #imagePreview,
#previewContainer.image-preview .top-bar .preview-box #videoPreview {
  border: none;
  border-radius: 0;
  max-width: 100vw;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  object-fit: cover;
}